<section id="home" class="uk-flex uk-flex-middle uk-flex-nowrap uk-text-center">
  <div class="uk-width-1-1 uk-text-center s7-padding-bottom--200 s7-padding-x--25 s7-container">
      <div class="s7-padding-x--100 s7-padding-top--100 s7-padding-bottom--25">
        <img uk-img data-src="assets/lif3-gaming.png" alt="Lif3 Gaming" />
      </div>
      <p class="uk-text-center uk-text-italic uk-margin-top">
        Walls are Trampolines
      </p>
      <h2 class="uk-h2 uk-text-uppercase uk-text-left uk-margin-remove-bottom s7-text-black">Follow Us</h2>
      <p class="uk-text-left uk-margin-remove-top uk-text-italic">We love this social media thing.</p>
      <p><a rel="me nofollow" target="_blank" href="https://discord.lif3gaming.gg/" class="uk-button icon-button uk-width-1-1"><i class="icon fab fa-discord"></i><span class="text">Discord</span></a></p>
      <p><a rel="me nofollow" target="_blank" href="https://twitter.com/Lif3GamingD2" class="uk-button icon-button uk-width-1-1"><i class="icon fab fa-twitter"></i><span class="text">Twitter</span></a></p>
      <p><a rel="me nofollow" target="_blank" href="https://www.instagram.com/lif3.gaming/" class="uk-button icon-button uk-width-1-1"><i class="icon fab fa-instagram"></i><span class="text">Instagram</span></a></p>

      <h2 class="uk-h2 uk-text-uppercase uk-text-left uk-margin-remove-bottom s7-text-black">Clan Twitch Channels</h2>
      <p class="uk-text-left uk-margin-remove-top uk-text-italic">Don't forget to like and subscribe!</p>
      <p><a href="https://www.twitch.tv/chillswil" class="uk-button uk-button-primary uk-width-1-1" target="_blank">Chillswil</a></p>
      <p><a href="https://www.twitch.tv/jj3571" class="uk-button uk-button-primary uk-width-1-1" target="_blank">JJ3571</a></p>
      <p><a href="https://www.twitch.tv/giftednuttv" class="uk-button uk-button-primary uk-width-1-1" target="_blank">GiftedNutTV</a></p>
      <p><a href="https://www.twitch.tv/cheatlancer" class="uk-button uk-button-primary uk-width-1-1" target="_blank">Cheatlancer</a></p>
      <p><a href="https://www.twitch.tv/thelen_music" class="uk-button uk-button-primary uk-width-1-1" target="_blank">thelen_music</a></p>
      <p><a href="https://www.twitch.tv/korinwolf12" class="uk-button uk-button-primary uk-width-1-1" target="_blank">Korinwolf12</a></p>
  </div>
</section>
